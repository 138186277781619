import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Button } from "@mui/material";
import { ICustomButton } from "../../../../../interfaces/globalComponents/buttons/material/ICustomButton";

export const SecondaryButton = ({
  title = "",
  className,
  onClick,
  startIcon,
  disabled,
  type = "button",
  sx,
  stopPropagation = false,
}: ICustomButton) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Button
      type={type}
      className={`rounded-3xl px-[24px] h-10 font-medium  transition-all ${className} ${
        disabled ? "opacity-50" : ""
      }`}
      onClick={(ev) => {
        stopPropagation && ev.stopPropagation();
        if (onClick) {
          void onClick();
        }
      }}
      startIcon={startIcon}
      disabled={disabled}
      sx={{
        textTransform: "none",
        backgroundImage: "none",
        bgcolor: theme.componentColors.secondary.secondaryContainer,
        color: theme.componentColors.secondary.onSecondaryContainer,
        "&:hover": {
          bgcolor: theme.componentColors.secondary.secondaryContainer,
          color: theme.componentColors.secondary.onSecondaryContainer,
          opacity: 1,
        },
        "&:disabled": {
          bgcolor: theme.componentColors.secondary.secondaryContainer,
          color: theme.componentColors.secondary.onSecondaryContainer,
        },
        "@media (min-width: 1240px)": {
          "&:hover": {
            bgcolor: theme.componentColors.secondary.secondaryContainer,
            color: theme.componentColors.secondary.onSecondaryContainer,
            opacity: 1,
          },
        },
        opacity: disabled ? 0.5 : 0.9,
        ...sx,
      }}
    >
      {title}
    </Button>
  );
};
