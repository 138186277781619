import { IParamsAPI } from "../../interfaces/apiRequests/IParamsAPI";
import { IUserData } from "../../interfaces/user/IUserData";
import { fetcher } from "../../lib/fetcher";
import * as Sentry from "@sentry/nextjs";

export async function uploadImageAWS(file: File, user: IUserData) {
  const preSigned = await getPreSignedUrl(file, user);
  await uploadFile(preSigned, file);
  return preSigned;
}

async function getPreSignedUrl(file: File, user: IUserData) {
  const params = {
    format: file.name.split(".").pop(),
    contentType: file.type ? file.type : "application/octet-stream",
  };
  const preSignedUrl = await generatePreSignedUrl(params, user);
  preSignedUrl.contentType = params.contentType;
  return preSignedUrl;
}

async function generatePreSignedUrl(
  params: IParamsAPI,
  user: IUserData
): Promise<IParamsAPI> {
  return (await fetcher(
    `media/signed-address?format=${params.format}&contentType=${params.contentType}`,
    {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    }
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "uploadImage",
      message: "error generating pre-signed url on uploadImage",
      data: e,
    });
    console.error(e);
  })) as Promise<IParamsAPI>;
}

async function uploadFile(params: IParamsAPI, file: File) {
  return await fetch(params.signedRequest, {
    method: "PUT",
    headers: {
      "Content-Type": params.contentType,
    },
    body: file,
  });
}

export const config = {
  api: {
    bodyParser: false,
  },
};
