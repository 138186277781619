import React, { useEffect } from "react";
import { OnboardingItemCard } from "../onboardingItemCard/OnboardingItemCard";
import { ISport } from "../../../../interfaces/globalComponents/filters/filterItems/ISport";
import { getSports } from "../../../../api/onboarding";
import * as Sentry from "@sentry/nextjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  getSelectedItems,
  initialSelectCards,
} from "../../../../lib/onboarding/selectCards";
import {
  handleSport,
  setIsLoading,
  setSports,
} from "../../../../redux/onboardingSlice";
import { MOCK_ARRAY_10 } from "../../../../lib/constants";
import { DefaultLoadingComponent } from "../../../global/defaultLoading/DefaultLoadingComponent";
import { OnboardingActionButtons } from "../onboardingActionButtons/OnboardingActionButtons";
import { updateFullUserData } from "../../../../lib/onboarding/updateData";
import { OnboardingItemsSection } from "../onboardingItemsSection/OnboardingItemsSection";
import { Typography } from "@mui/material";
import {
  setShowSnackbar,
  setSnackbarText,
} from "../../../../redux/globalSlice";

export const OnboardingSports = () => {
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice
  );
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const dispatch = useDispatch();

  useEffect(() => {
    onboardingRedux?.userData &&
      (async function getSportsData() {
        dispatch(setIsLoading(true));
        const sportsFromApi = await getSports();
        dispatch(setIsLoading(false));
        initialSelectCards(
          onboardingRedux.userData,
          "sports",
          sportsFromApi,
          (value: ISport[]) => {
            dispatch(setSports(value));
          }
        );
      })().catch((error: Error) => {
        Sentry.captureException({
          category: "OnboardingSports",
          message: "error on getSportsData",
          data: error,
        });
      });
  }, [onboardingRedux.userData, dispatch]);

  return (
    <>
      {onboardingRedux.isEditing && (
        <Typography
          className="text-[28px] font-medium"
          sx={{
            color: componentColors.surface.onSurfaceDetachable,
          }}
        >
          Sports
        </Typography>
      )}

      <OnboardingItemsSection>
        {onboardingRedux.sports.length > 0
          ? onboardingRedux.sports.map((item) => {
              return (
                <OnboardingItemCard
                  key={item.id}
                  title={item.title}
                  image={item.imageUrl}
                  selected={item.isSelected}
                  onClick={() => {
                    dispatch(handleSport(item));
                  }}
                />
              );
            })
          : MOCK_ARRAY_10.map((item) => (
              <DefaultLoadingComponent key={item} width={156} height={129} />
            ))}
      </OnboardingItemsSection>

      <OnboardingActionButtons
        currentAction={async () => {
          const selectedSportsIds = getSelectedItems(onboardingRedux.sports);

          const newUser = await updateFullUserData(onboardingRedux.userData, {
            sports: selectedSportsIds,
          });
          if (onboardingRedux.isEditing) {
            dispatch(setShowSnackbar(true));
            dispatch(setSnackbarText("Sports Saved!"));
          }
          return newUser;
        }}
      />
    </>
  );
};
