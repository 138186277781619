import { Stack } from "@mui/material";
import { OnboardingTitle } from "./OnboardingTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { OnboardingProgressBar } from "./OnboardingProgressBar";
import { Pod1umSvgIcon } from "../../../global/svg/Pod1umSvgIcon";

export const OnboardingHeading = () => {
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice
  );
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  if (onboardingRedux.isEditing) {
    return;
  }

  return (
    <Stack>
      <div className="space-y-[32px] mb-[40px]">
        <Pod1umSvgIcon color={componentColors.surface.onSurface} />
        <OnboardingProgressBar step={onboardingRedux.step} />
      </div>

      <OnboardingTitle
        title={onboardingRedux.currentTitle}
        subtitle={onboardingRedux.currentSubtitle}
      />
    </Stack>
  );
};
