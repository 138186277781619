import { IInjuryAlert } from "../../../interfaces/onboarding/injuries/IInjuryAlert";
import { TbAlertTriangle } from "react-icons/tb";
export const InjuryAlert = ({ positions }: IInjuryAlert) => {
  return (
    <div
      style={{ ...positions }}
      className={`absolute rounded-full p-[6px] bg-red-500 
       opacity-80 hover:opacity-100 cursor-pointer transition-all flex items-center justify-center`}
    >
      <TbAlertTriangle className="text-xl" />
    </div>
  );
};
