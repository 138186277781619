import { getUser, updateUser } from "../../api/user";
import { IUserData } from "../../interfaces/user/IUserData";
import { updateUserLocalStorage } from "../user/localStorage";
import { uploadImageAWS } from "../../pages/api/uploadImage";
import { EquipmentFromApi } from "../../interfaces/onboarding/EquipmentFromApi";

export async function updateOnboardData(
  user: IUserData,
  dataToUpdate: object,
  updateLocalStorage = false
): Promise<IUserData> {
  const updatedUser = await updateUser(user, {
    ...dataToUpdate,
    email: user.email,
    firstName: user.firstName,
  });
  updateLocalStorage && updateUserLocalStorage(updatedUser, dataToUpdate);
  return updatedUser;
}

export async function updateFullUserData(
  user: IUserData,
  dataToUpdate: object
): Promise<IUserData> {
  try {
    const updatedUser = await updateUser(user, {
      ...dataToUpdate,
      email: user.email,
      firstName: user.name,
    });
    updateUserLocalStorage(user, updatedUser);

    return { ...user, ...updatedUser };
  } catch (error) {
    throw error;
  }
}

export async function updateUserProfileImage(user: IUserData, file: FileList) {
  if (file.item(0)) {
    const preSignedUrl = await uploadImageAWS(file.item(0), user);
    const dataToUpdate = { avatarFilename: preSignedUrl.fileName };
    await updateFullUserData(user, dataToUpdate);
    const resGetNewInfo = await getUser(user);
    return resGetNewInfo;
  } else {
    return;
  }
}

export const selectAllCards = (
  equipmentCards: EquipmentFromApi[],
  value: boolean = false
) => {
  const newEquipmentsList = equipmentCards.map((equipment) => {
    const updateData: EquipmentFromApi = {
      ...equipment,
      isSelected: value ? value : !equipment.isSelected,
    };
    return updateData;
  });

  return newEquipmentsList;
};
