import { cardItem } from "../../interfaces/onboarding/CardItem";
import { IOnboardingSlice } from "../../interfaces/onboarding/redux/IOnboardingSlice";
import { IUserData } from "../../interfaces/user/IUserData";

export function initialSelectCards(
  userData: IUserData,
  propertyToChange: string,
  initialItems: cardItem[],
  setStateFuncion: (value: React.SetStateAction<cardItem[]>) => void
) {
  const newObjectiveList = initialItems.map((item) => {
    const arrayToFind = userData[`${propertyToChange}`] as cardItem[];
    const find = arrayToFind.find(
      (userObjective: cardItem) => userObjective.id === item.id
    );
    if (find) {
      item.isSelected = true;
    } else {
      item.isSelected = false;
    }
    return item;
  });
  setStateFuncion(newObjectiveList);
}

export function handleSelectedItem(
  items: cardItem[],
  card: cardItem,
  setStateFuncion: (value: React.SetStateAction<cardItem[]>) => void
) {
  const newObjectiveList = items.map((objective) => {
    if (card.id === objective.id) {
      const updateData = {
        ...objective,
        isSelected: !objective.isSelected,
      };
      return updateData;
    }
    return objective;
  });
  setStateFuncion(newObjectiveList);
}

export function getSelectedItems(items: cardItem[]) {
  const selecteditems: number[] = [];
  items?.forEach((item) => {
    if (item.isSelected) {
      selecteditems.push(item.id);
    }
  });
  return selecteditems;
}

export function getSelectedItemsComplete(onboardingSlice: IOnboardingSlice) {
  const selectedItemsObject: { sports: number[]; objectives: number[] } = {
    sports: [],
    objectives: null,
  };
  onboardingSlice.sports?.length > 0 &&
    onboardingSlice.sports?.forEach((item) => {
      if (item.isSelected) {
        selectedItemsObject.sports = [...selectedItemsObject?.sports, item.id];
      }
    });

  onboardingSlice.objectives?.length > 0 &&
    onboardingSlice.objectives?.forEach((item) => {
      if (item.isSelected) {
        selectedItemsObject.objectives = [
          ...selectedItemsObject?.objectives,
          item.id,
        ];
      }
    });
  return selectedItemsObject;
}
