import React, { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { OnboardingActionButtons } from "../onboardingActionButtons/OnboardingActionButtons";
import { updateFullUserData } from "../../../../lib/onboarding/updateData";
import useUser from "../../../../hooks/user/useUser";
import {
  setBodyAreas,
  setIsLoading,
  setUserData,
  setUserInjuries,
} from "../../../../redux/onboardingSlice";
import frontBodyUrl from "../../../../public/images/body_front.png";
import backBodyUrl from "../../../../public/images/body_back.png";
import {
  backInjuriesArrangements,
  frontInjuriesArrangements,
} from "../../../../lib/injuriesArrangements";
import { InjuryAlert } from "../../injuries/InjuryAlert";
import { DefaultLoadingComponent } from "../../../global/defaultLoading/DefaultLoadingComponent";
import { Stack } from "@mui/material";
import { OnboardingInjuriesList } from "./OnboardingInjuriesList";
import { getUserData } from "../../../../lib/generic";
import { OnboardingInjuryModal } from "./OnboardingInjuryModal";
import { deleteInjury, getBodyAreas } from "../../../../api/onboarding";

export const OnboardingInjuries = () => {
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice
  );
  const { user } = useUser();
  const dispatch = useDispatch();
  const [isLoadingInjuries, setIsLoadingInjuries] = useState(true);

  const handleAddInjuries = useCallback(async () => {
    onboardingRedux.injuriesToBeDeleted.forEach((injury) => {
      if (
        onboardingRedux.userData.injuriesHistory.find(
          (item) => item.injuryId == injury.injuryId
        )
      ) {
        deleteInjury(onboardingRedux.userData, injury.injuryId).catch(
          (error: Error) => {
            Sentry.captureException({
              category: "OnboardingInjuries",
              message: "error on deleteInjury",
              data: error,
            });
          }
        );
      }
    });

    const newUser = await updateFullUserData(onboardingRedux.userData, {
      injuries: onboardingRedux.injuries,
    });
    return newUser;
  }, [
    onboardingRedux.userData,
    onboardingRedux.injuries,
    onboardingRedux.injuriesToBeDeleted,
  ]);

  useEffect(() => {
    user?.token &&
      Promise.all([getUserData(user), getBodyAreas(user)])
        .then((values) => {
          dispatch(setUserData(values[0]));
          dispatch(setUserInjuries(values[0].injuriesHistory));
          dispatch(setBodyAreas(values[1]));
          dispatch(setIsLoading(false));
          setIsLoadingInjuries(false);
        })
        .catch((error: Error) => {
          Sentry.captureException({
            category: "OnboardingInjuries",
            message: "error on updateUserData",
            data: error,
          });
        });
  }, [user, dispatch]);

  return (
    <>
      <OnboardingInjuryModal />
      <div className="flex flex-col items-center lg:items-end">
        {isLoadingInjuries ? (
          <DefaultLoadingComponent height={384} width={240} />
        ) : (
          <Stack className="w-full flex justify-between space-y-10 lg:space-y-0 items-center lg:flex-row">
            <div />
            {onboardingRedux.showFrontInjuryImage && (
              <div
                data-cy="body-front"
                className="bg-center w-60 h-96  relative  bg-no-repeat bg-contain"
                style={{ backgroundImage: `url(${frontBodyUrl.src})` }}
              >
                {onboardingRedux.injuries.map((item) => {
                  const userInjury = frontInjuriesArrangements.find(
                    (userInjuryItem) =>
                      userInjuryItem.id === item.injury.bodyMarker
                  );
                  if (userInjury) {
                    return (
                      <InjuryAlert
                        key={userInjury.id}
                        positions={userInjury.positions}
                      />
                    );
                  }
                })}
              </div>
            )}
            {!onboardingRedux.showFrontInjuryImage && (
              <div
                data-cy="body-front"
                className="bg-center w-60 h-96  relative  bg-no-repeat bg-contain"
                style={{ backgroundImage: `url(${backBodyUrl.src})` }}
              >
                {onboardingRedux.injuries.map((item) => {
                  const userInjury = backInjuriesArrangements.find(
                    (userInjuryItem) =>
                      userInjuryItem.id === item.injury.bodyMarker
                  );
                  if (userInjury) {
                    return (
                      <InjuryAlert
                        key={userInjury.id}
                        positions={userInjury.positions}
                      />
                    );
                  }
                })}
              </div>
            )}
            <OnboardingInjuriesList />
          </Stack>
        )}
      </div>

      {!onboardingRedux.isEditing && (
        <OnboardingActionButtons currentAction={handleAddInjuries} />
      )}
    </>
  );
};
