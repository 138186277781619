import React from "react";
import { IOnboardingProgressBar } from "../../../../interfaces/onboarding/onboardingHeading/IOnboardingProgressBar";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const OnboardingProgressBar = ({ step }: IOnboardingProgressBar) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <div
      style={{
        backgroundColor: componentColors.surface.surfContainerHighest,
      }}
      className="w-full h-[8px]"
    >
      <div
        style={{
          width: `${(step / 6) * 100}%`,
          backgroundColor: componentColors.primary.primary,
        }}
        className={`h-full transition-[width]`}
      />
    </div>
  );
};
