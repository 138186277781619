import { Autocomplete, TextField } from "@mui/material";
import React from "react";

import { IAutoCompleteInput } from "../../../../interfaces/globalComponents/inputs/autoCompleteInput/IAutoCompleteInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const AutoCompleteInput = <T,>({
  label,
  options,
  onChange,
  value,
  placeholder,
  onClear,
  onInputChange,
  className,
  getOptionLabel,
  renderOption,
  isFetchingItems,
  clearOnBlur,
}: IAutoCompleteInput<T>) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <Autocomplete
      className={className}
      getOptionLabel={getOptionLabel}
      disablePortal
      loading={isFetchingItems}
      id="auto-complete-app"
      onInputChange={onInputChange}
      clearOnBlur={clearOnBlur}
      onChange={(event, reason) => {
        if (!reason && onClear) {
          onClear();
          return;
        }
        onChange && onChange(event, reason);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      renderOption={renderOption}
      options={options}
      value={value}
      sx={{
        "& fieldset": {
          borderColor: componentColors.outline.outline,
        },
        ".Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: componentColors.primary.primary,
        },
        "& button svg": {
          color: componentColors.surface.onSurface,
        },
        "& label.Mui-focused": {
          color: componentColors.primary.primary,
        },
        "& input": {
          color: componentColors.surface.onSurface,
        },
      }}
    />
  );
};
