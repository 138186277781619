import { TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ITextInput } from "../../../../interfaces/globalComponents/inputs/textInput/ITextInput";

export const TextInput = ({
  label,
  placeholder,
  type = "text",
  onChange,
  onFocus,
  value,
  rows = 1,
  className,
  inputProps,
  error,
  helperText,
  disabled,
  autoComplete,
  securityText,
}: ITextInput) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <TextField
      className={className}
      sx={{
        "& input": {
          color: componentColors.surface.onSurface,
          "-webkit-text-security": securityText ? "disc" : null,
        },
        "& fieldset": {
          borderColor: error
            ? componentColors.error.error
            : componentColors.outline.outline,
        },
        "& fieldset .Mui-focused": {
          borderColor: error
            ? componentColors.error.error
            : componentColors.outline.outline,
        },
      }}
      placeholder={placeholder}
      label={label}
      type={type}
      onChange={onChange}
      multiline={rows > 1 ? true : false}
      rows={rows}
      value={value === undefined ? null : value}
      inputProps={inputProps}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
      disabled={disabled}
      autoComplete={autoComplete ? "off" : ""}
    />
  );
};
