import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { IDateInput } from "../../../../interfaces/globalComponents/inputs/dateInput/IDateInput";
import dayjs from "dayjs";
import useUser from "../../../../hooks/user/useUser";

export const DateInput = ({
  onChange,
  dateFormat,
  value,
  label,
  minDate,
  maxDate,
}: IDateInput) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const { user } = useUser();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format={
          dateFormat ? dateFormat : user?.dateFormat ? user?.dateFormat : null
        }
        label={label}
        minDate={minDate ? dayjs(minDate) : null}
        maxDate={maxDate ? dayjs(maxDate) : null}
        value={value ? dayjs(value) : null}
        onChange={(date) => {
          if (date.isValid()) onChange(date);
        }}
        sx={{
          "& fieldset": {
            borderColor: componentColors.outline.outline,
          },
          ".Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: componentColors.primary.primary,
          },
          "& input": {
            color: componentColors.surface.onSurface,
          },
          "& button svg": {
            color: componentColors.surface.onSurface,
          },
          "& label.Mui-focused": {
            color: componentColors.primary.primary,
          },
          ".MuiPickersDay-today": {
            borderColor: `${componentColors.primary.primary} !important`,
            color: `${componentColors.primary.primary} !important`,
          },
          ".Mui-selected": {
            color: `${componentColors.primary.onPrimary} !important`,
          },
        }}
      />
    </LocalizationProvider>
  );
};
