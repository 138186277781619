import { IUserData } from "../../interfaces/user/IUserData";
import { fetcher } from "../fetcher";
import * as Sentry from "@sentry/nextjs";

export async function getTimezones(user: IUserData) {
  return (await fetcher("users/timezones", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "details",
      message: "Error on getTimezones function on details page",
      data: e,
    });
    console.error(e);
  })) as string[];
}
