import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Stack, Typography } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { IconButton } from "../../../global/buttons/material/iconButton/IconButton";
import { IOnboardingInjuryItem } from "../../../../interfaces/onboarding/onboardingInjuryItem/IOnboardingInjuryItem";
import { deleteInjury } from "../../../../api/onboarding";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { setUserInjuries } from "../../../../redux/onboardingSlice";

export const OnboardingInjuryItem = ({ injury }: IOnboardingInjuryItem) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice
  );
  const dispatch = useDispatch();
  const router = useRouter();

  const deleteInjuryHandler = () => {
    if (router.asPath.includes("profile")) {
      deleteInjury(onboardingRedux.userData, injury.injuryId).catch(
        (error: Error) => {
          Sentry.captureException({
            category: "OnboardingInjuryItem",
            message: "error on getting body areas",
            data: error,
          });
        }
      );
    }

    dispatch(
      setUserInjuries(
        onboardingRedux.injuries.filter(
          (item) => item.injuryId != injury.injuryId
        )
      )
    );
  };

  return (
    <Stack
      sx={{
        borderBottom: "1px solid",
        borderColor: componentColors.surface.onSurfaceVariant,
      }}
      direction={"row"}
      className="py-[8px] justify-between"
    >
      <div>
        <Typography
          sx={{ color: componentColors.surface.onSurface }}
          className="text-[16px]"
        >
          {injury.injury.title}
        </Typography>
        <Typography
          className="text-[12px]"
          sx={{ color: componentColors.surface.onSurface }}
        >
          {injury.notes}
        </Typography>
      </div>
      <IconButton
        onClick={() => {
          deleteInjuryHandler();
        }}
        icon={<DeleteOutline sx={{ color: componentColors.error.error }} />}
      />
    </Stack>
  );
};
