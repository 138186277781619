import React, { useEffect, useState } from "react";
import useUser from "../../../../hooks/user/useUser";
import { useDispatch, useSelector } from "react-redux";
import useImageSize from "../../../../hooks/global/useImageSize";
import { RootState } from "../../../../redux/store";
import { Avatar } from "@mui/material";
import { OutlinedButton } from "../../../global/buttons/material/outlinedButton/OutlinedButton";
import { Add, ModeEdit } from "@mui/icons-material";
import { updateUserProfileImage } from "../../../../lib/onboarding/updateData";
import { setAvatarUrl } from "../../../../redux/userSlice";
import { OnboardingActionButtons } from "../onboardingActionButtons/OnboardingActionButtons";
import { setIsLoading } from "../../../../redux/onboardingSlice";

export const OnboardingProfileImage = () => {
  const { user, setUser } = useUser();
  const theme = useSelector((state: RootState) => state.theme);
  const resizedImage = useImageSize({
    imageUrl: user?.avatarUrl,
    size: 500,
    isProfile: true,
    originalFormat: true,
  })[0];
  const [uploadedUrl, setUploadedUrl] = useState<string>(null);
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);
  const [currentFileList, setCurrentFileList] = useState<FileList>(null);
  const dispatch = useDispatch();

  const selectImageHandler = (fileList: FileList) => {
    const file = fileList[0];
    setCurrentFileList(fileList);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedUrl(e.target.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImageHandler = async () => {
    if (currentFileList) {
      setLoadingUploadImage(true);
      const newUser = await updateUserProfileImage(user, currentFileList);
      setUser(newUser);
      setLoadingUploadImage(false);
      dispatch(setAvatarUrl(uploadedUrl));
      return newUser;
    }
  };

  useEffect(() => {
    dispatch(setIsLoading(false));
  }, [dispatch]);

  return (
    <div>
      <div
        onClick={() =>
          !loadingUploadImage && document.getElementById("fileInput").click()
        }
        className="flex flex-col items-center justify-center space-y-[24px]"
      >
        <Avatar
          className={`w-[120px] h-[120px] font-semibold cursor-pointer ${
            loadingUploadImage ? "opacity-50" : "opacity-100"
          }`}
          src={uploadedUrl ? uploadedUrl : resizedImage}
          sx={{
            bgcolor: theme.componentColors.primary.onPrimary,
            color: theme.componentColors.surface.onSurface,
          }}
        >
          {user?.name?.split(" ")[0]?.split("")[0]}
          {user?.name?.split(" ")[1]?.split("")[0]}
        </Avatar>
        <OutlinedButton
          disabled={loadingUploadImage}
          startIcon={user?.avatarUrl ? <ModeEdit /> : <Add />}
          title={user?.avatarUrl ? "Edit Profile Image" : "Add Profile Image"}
          borderColor={theme.componentColors.outline.outline}
        />
        <input
          className="hidden"
          type="file"
          id="fileInput"
          data-cy="profile-image"
          onError={() => {
            setLoadingUploadImage(false);
          }}
          onChange={(e) => {
            selectImageHandler(e.target.files);
          }}
          accept="image/jpeg, image/jpg, image/png"
        />
      </div>

      <OnboardingActionButtons
        currentAction={async () => {
          return uploadImageHandler();
        }}
      />
    </div>
  );
};
