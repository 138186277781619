import React from "react";
import { DefaultModal } from "../../../global/modals/material/DefaultModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setIsOpenOnboardingModal,
  setShowCancelModal,
} from "../../../../redux/onboardingSlice";
import { Stack, Typography } from "@mui/material";
import { PrimaryButton } from "../../../global/buttons/material/primaryButton/PrimaryButton";
import { SecondaryButton } from "../../../global/buttons/material/secondaryButton/SecondaryButton";
import { useRouter } from "next/router";

export const CancelOnboardingModal = () => {
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice
  );
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const dispatch = useDispatch();
  const router = useRouter();

  return (
    <DefaultModal
      onClose={() => dispatch(setShowCancelModal(false))}
      open={onboardingRedux.showCancelOnboardingModal}
    >
      <div className="lg:w-[450px] px-[20px] lg:p-[46px]">
        <Typography
          sx={{
            color: componentColors.surface.onSurfaceDetachable,
          }}
          className="font-medium text-[28px]"
        >
          Exit onboarding?
        </Typography>
        <Typography
          sx={{
            color: componentColors.surface.onSurface,
          }}
        >
          You can edit these details from your profile page at any time.
        </Typography>
        <Stack className="mt-[20px] space-y-[10px]">
          <PrimaryButton
            title="Cancel onboarding"
            onClick={() => {
              dispatch(setShowCancelModal(false));
              dispatch(setIsOpenOnboardingModal(false));
              if (router.asPath.includes("onboarding")) {
                void router.push("/");
              }
            }}
          />

          <SecondaryButton
            onClick={() => {
              dispatch(setShowCancelModal(false));
            }}
            title="Continue onboarding"
          />
        </Stack>
      </div>
    </DefaultModal>
  );
};
