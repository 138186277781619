import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const OnboardingItemsSection = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const router = useRouter();
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice
  );

  if (onboardingRedux.isEditing) {
    return (
      <div
        className={`grid grid-cols-2 scrollbar-hide lg:scrollbar-default
        sm:grid-cols-3 mx-auto md:grid-cols-5 2xl:grid-cols-6  3xl:grid-cols-7 overflow-x-hidden 
                 max-h-[40vh] lg:max-h-[500px]  pr-4 gap-4 gap-y-10 `}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={`lg:w-[844px] grid grid-cols-2 scrollbar-hide lg:scrollbar-default
        sm:grid-cols-3  mx-auto md:grid-cols-5 overflow-x-hidden 
                 ${
                   router.asPath.includes("/onboarding")
                     ? "max-h-[60vh]"
                     : "max-h-[300px] 3xl:max-h-[400px] lg:overflow-y-scroll"
                 } pr-[4px] gap-[16px] `}
    >
      {children}
    </div>
  );
};
