import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { ISelectInput } from "../../../../interfaces/globalComponents/inputs/selectInput/ISelectInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const SelectInput = ({
  options,
  label,
  placeholder,
  defaultValue,
  onChange,
}: ISelectInput) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  return (
    <FormControl fullWidth>
      <InputLabel id={`id-label`}>{label}</InputLabel>
      <Select
        sx={{
          "& fieldset": {
            borderColor: componentColors.outline.outline,
          },
          "& div": {
            color: !defaultValue ? componentColors.outline.outline : null,
          },
        }}
        placeholder={placeholder}
        labelId={`id-label`}
        id="id-select"
        label={label}
        multiline
        displayEmpty
        value={defaultValue ? defaultValue : "placeholder"}
        onChange={onChange}
      >
        <MenuItem value="placeholder" disabled>
          {placeholder}
        </MenuItem>
        {options.map((item, i) => {
          return (
            <MenuItem value={item.option} key={i}>
              {item.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
