import React, { useEffect, useState } from "react";
import { OnboardingActionButtons } from "../onboardingActionButtons/OnboardingActionButtons";
import { updateFullUserData } from "../../../../lib/onboarding/updateData";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { DateInput } from "../../../global/inputs/dateInput/DateInput";
import { TextInput } from "../../../global/inputs/textInput/TextInput";
import { getTimezones } from "../../../../lib/onboarding/getTimezones";
import * as Sentry from "@sentry/nextjs";
import {
  setDateFormat,
  setDateOfBirth,
  setFullDetails,
  setGender,
  setHeight,
  setIsLoading,
  setLevel,
  setTimezones,
  setUnit,
  setUserTimezone,
  setWeight,
} from "../../../../redux/onboardingSlice";
import useUser from "../../../../hooks/user/useUser";
import { IDateFormat } from "../../../../interfaces/onboarding/redux/IOnboardingSlice";
import { AutoCompleteInput } from "../../../global/inputs/autoCompleteInput/AutoCompleteInput";
import {
  dateFormats,
  genderList,
  levels,
  unitPreference,
} from "../../../../lib/onboarding/details";
import { MOCK_ARRAY_8 } from "../../../../lib/constants";
import { DefaultLoadingComponent } from "../../../global/defaultLoading/DefaultLoadingComponent";

export const OnboardingDetails = () => {
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice
  );
  const dispatch = useDispatch();
  const { user } = useUser();
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);

  useEffect(() => {
    user?.token &&
      getTimezones(user)
        .then((timezones) => {
          dispatch(setTimezones(timezones));
          dispatch(
            setFullDetails({
              dob: user.dob,
              dateFormat: user.dateFormat as IDateFormat,
              weight: user.weight,
              height: user.height,
              gender: user.gender,
              units: user.units,
              timezone: user.timezone,
              level: user.level,
            })
          );
          setIsLoadingDetails(false);
          dispatch(setIsLoading(false));
        })
        .catch((error: Error) => {
          Sentry.captureException({
            category: "OnboardingDetails",
            message: "error getTimezones",
            data: error,
          });
        });
  }, [user, dispatch]);

  return (
    <div>
      <div className="grid grid-cols-2 mx-auto lg:w-[678px] gap-x-[24px] gap-y-[40px] max-h-[400px] overflow-y-scroll pt-[6px]">
        {isLoadingDetails ? (
          MOCK_ARRAY_8.map((item) => (
            <DefaultLoadingComponent height={50} key={item} />
          ))
        ) : (
          <>
            <DateInput
              label="Date of Birth"
              onChange={(date) => {
                const newDateIsoString = date.toISOString();
                dispatch(setDateOfBirth(newDateIsoString));
              }}
              dateFormat={onboardingRedux.details.dateFormat}
              value={
                onboardingRedux.details.dob != null
                  ? new Date(onboardingRedux.details.dob)
                  : null
              }
            />

            <AutoCompleteInput<string>
              label="Gender"
              options={genderList}
              value={genderList.find(
                (item) =>
                  item.toLocaleLowerCase() === onboardingRedux.details.gender
              )}
              placeholder="Select your Gender"
              onClear={() => {
                dispatch(setGender(null));
              }}
              onChange={(_, value) => {
                if (value) {
                  dispatch(setGender(value.toLowerCase()));
                }
              }}
            />
            <AutoCompleteInput<string>
              label="Training Level"
              options={levels}
              value={levels.find(
                (item) =>
                  item.toLocaleLowerCase() === onboardingRedux.details.level
              )}
              placeholder="Select your Training Level"
              onClear={() => {
                dispatch(setLevel(null));
              }}
              onChange={(_, value) => {
                if (value) {
                  dispatch(setLevel(value.toLowerCase()));
                }
              }}
            />

            <AutoCompleteInput<string>
              label="Unit Preference"
              options={unitPreference}
              value={unitPreference.find(
                (item) =>
                  item.toLocaleLowerCase() === onboardingRedux.details.units
              )}
              placeholder="Select your unit preference"
              onClear={() => {
                dispatch(setUnit(null));
              }}
              onChange={(_, value) => {
                if (value) {
                  dispatch(setUnit(value.toLowerCase()));
                }
              }}
            />

            <TextInput
              label={
                onboardingRedux.details.units === "imperial"
                  ? "Height in ft"
                  : "Height in cm"
              }
              placeholder={
                onboardingRedux.details.units === "imperial" ? "ft" : "cm"
              }
              type="number"
              onChange={(event) => {
                dispatch(setHeight(Number(event.target.value)));
              }}
              value={onboardingRedux.details.height}
            />

            <TextInput
              label={
                onboardingRedux.details.units === "imperial"
                  ? "Weight in lb"
                  : "Weight in kg"
              }
              placeholder={
                onboardingRedux.details.units === "imperial" ? "lb" : "kg"
              }
              type="number"
              onChange={(event) => {
                dispatch(setWeight(Number(event.target.value)));
              }}
              value={onboardingRedux.details.weight}
            />

            <AutoCompleteInput<string>
              label="Timezone"
              options={onboardingRedux.timezones}
              value={onboardingRedux.details.timezone}
              placeholder="Select your timezone"
              onClear={() => {
                dispatch(setUserTimezone(null));
              }}
              onChange={(_, value) => {
                if (value) {
                  dispatch(setUserTimezone(value));
                }
              }}
            />

            <AutoCompleteInput<string>
              label="Date Format"
              options={dateFormats}
              value={dateFormats.find(
                (item) => item === onboardingRedux.details.dateFormat
              )}
              placeholder="Select your date format"
              onClear={() => {
                dispatch(setDateFormat(null));
              }}
              onChange={(_, value) => {
                if (value) {
                  dispatch(setDateFormat(value as IDateFormat));
                }
              }}
            />
          </>
        )}
      </div>

      <OnboardingActionButtons
        currentAction={async () => {
          const newUser = await updateFullUserData(onboardingRedux.userData, {
            ...onboardingRedux.details,
          });
          return newUser;
        }}
        disablePrimaryButton={
          !onboardingRedux.details.dateFormat == null ||
          onboardingRedux.details.dob == null ||
          onboardingRedux.details.gender == null ||
          onboardingRedux.details.height == null ||
          onboardingRedux.details.level == null ||
          onboardingRedux.details.timezone == null ||
          onboardingRedux.details.units == null ||
          onboardingRedux.details.weight === null
        }
      />
    </div>
  );
};
