import React, { useEffect } from "react";
import { OnboardingItemCard } from "../onboardingItemCard/OnboardingItemCard";
import { getEquipments } from "../../../../api/onboarding";
import * as Sentry from "@sentry/nextjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  getSelectedItems,
  initialSelectCards,
} from "../../../../lib/onboarding/selectCards";
import {
  handleEquipment,
  handleSelectAllEquipments,
  handleUnselectAllEquipments,
  setEquipments,
  setIsLoading,
} from "../../../../redux/onboardingSlice";
import { MOCK_ARRAY_10 } from "../../../../lib/constants";
import { DefaultLoadingComponent } from "../../../global/defaultLoading/DefaultLoadingComponent";
import { OnboardingActionButtons } from "../onboardingActionButtons/OnboardingActionButtons";
import { updateFullUserData } from "../../../../lib/onboarding/updateData";
import { OnboardingItemsSection } from "../onboardingItemsSection/OnboardingItemsSection";
import { EquipmentFromApi } from "../../../../interfaces/onboarding/EquipmentFromApi";
import { TextButton } from "../../../global/buttons/material/textButton/TextButton";
import { DoneAll } from "@mui/icons-material";
import { Typography } from "@mui/material";
import {
  setShowSnackbar,
  setSnackbarText,
} from "../../../../redux/globalSlice";

export const OnboardingEquipments = () => {
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice
  );
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const dispatch = useDispatch();

  useEffect(() => {
    onboardingRedux?.userData &&
      (async function getEquipmentsData() {
        dispatch(setIsLoading(true));
        const equipmentsFromApi = await getEquipments();
        dispatch(setIsLoading(false));
        initialSelectCards(
          onboardingRedux.userData,
          "equipments",
          equipmentsFromApi,
          (value: EquipmentFromApi[]) => {
            dispatch(setEquipments(value));
          }
        );
      })().catch((error: Error) => {
        Sentry.captureException({
          category: "OnboardingEquipments",
          message: "error on getEquipmentsData",
          data: error,
        });
      });
  }, [onboardingRedux.userData, dispatch]);

  return (
    <div>
      {onboardingRedux.isEditing && (
        <Typography
          className="text-[28px] font-medium"
          sx={{
            color: componentColors.surface.onSurfaceDetachable,
          }}
        >
          Equipment
        </Typography>
      )}

      <div className="w-full justify-end flex">
        <TextButton
          onClick={() => {
            if (onboardingRedux.isAllEquipmentsSelected) {
              dispatch(handleUnselectAllEquipments());
              return;
            }
            dispatch(handleSelectAllEquipments());
          }}
          startIcon={<DoneAll />}
          text={
            onboardingRedux.isAllEquipmentsSelected
              ? "Deselect All"
              : "Select All"
          }
          className="mb-[10px] px-[24px]"
        />
      </div>

      <OnboardingItemsSection>
        {onboardingRedux.equipments.length > 0
          ? onboardingRedux.equipments.map((item) => {
              return (
                <OnboardingItemCard
                  key={item.id}
                  title={item.title}
                  image={item.imageUrl}
                  selected={item.isSelected}
                  onClick={() => {
                    dispatch(handleEquipment(item));
                  }}
                />
              );
            })
          : MOCK_ARRAY_10.map((item) => (
              <DefaultLoadingComponent key={item} width={156} height={129} />
            ))}
      </OnboardingItemsSection>

      <OnboardingActionButtons
        currentAction={async () => {
          const selectedObjectivesIds = getSelectedItems(
            onboardingRedux.equipments
          );
          const newUser = await updateFullUserData(onboardingRedux.userData, {
            equipments: selectedObjectivesIds,
          });

          if (onboardingRedux.isEditing) {
            dispatch(setShowSnackbar(true));
            dispatch(setSnackbarText("Equipments Saved!"));
          }

          return newUser;
        }}
      />
    </div>
  );
};
