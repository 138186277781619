import React from "react";
import { IOnboardingTitle } from "../../../../interfaces/onboarding/onboardingHeading/IOnboardingTitle";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const OnboardingTitle = ({ title, subtitle }: IOnboardingTitle) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <div>
      <Typography
        className="text-[28px] font-medium"
        sx={{
          color: componentColors.surface.onSurfaceDetachable,
        }}
      >
        {title}
      </Typography>

      <Typography
        className="text-[14px] lg:text-[20px]"
        sx={{
          color: componentColors.surface.onSurface,
        }}
      >
        {subtitle}
      </Typography>
    </div>
  );
};
