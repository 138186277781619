export const frontInjuriesArrangements = [
  {
    id: "Front 1",

    positions: { top: 0, left: 103 },
  },
  {
    id: "Front 2",
    positions: { top: 30, left: 103 },
  },
  {
    id: "Front 3",
    positions: { top: 60, left: 60 },
  },
  {
    id: "Front 4",
    positions: { top: 60, left: 80 },
  },
  {
    id: "Front 5",
    positions: { top: 55, right: 85 },
  },
  {
    id: "Front 6",
    positions: { top: 60, right: 60 },
  },
  {
    id: "Front 7",
    positions: { top: 90, left: 80 },
  },
  {
    id: "Front 8",
    positions: { top: 80, left: 100 },
  },
  {
    id: "Front 9",
    positions: { top: 90, right: 80 },
  },
  {
    id: "Front 10",
    positions: { top: 100, left: 55 },
  },
  {
    id: "Front 11",
    positions: { top: 90, right: 60 },
  },
  {
    id: "Front 12",
    positions: { top: 120, left: 45 },
  },
  {
    id: "Front 13",
    positions: { top: 120, right: 40 },
  },
  {
    id: "Front 14",
    positions: { top: 140, left: 50 },
  },
  {
    id: "Front 15",
    positions: { top: 145, right: 50 },
  },
  {
    id: "Front 16",
    positions: { top: 125, left: 85 },
  },
  {
    id: "Front 17",
    positions: { top: 110, right: 105 },
  },
  {
    id: "Front 18",
    positions: { top: 125, right: 85 },
  },
  {
    id: "Front 19",
    positions: { top: 160, left: 105 },
  },
  {
    id: "Front 20",
    positions: { bottom: 190, left: 83 },
  },
  {
    id: "Front 21",
    positions: { bottom: 190, right: 80 },
  },
  {
    id: "Front 22",
    positions: { top: 190, left: 40 },
  },
  {
    id: "Front 23",
    positions: { top: 190, right: 40 },
  },
  {
    id: "Front 24",
    positions: { left: 85, bottom: 140 },
  },
  {
    id: "Front 25",
    positions: { right: 85, bottom: 140 },
  },
  {
    id: "Front 26",
    positions: { bottom: 90, left: 90 },
  },
  {
    id: "Front 27",
    positions: { bottom: 90, right: 90 },
  },
  {
    id: "Front 28",
    positions: { bottom: 50, left: 85 },
  },
  {
    id: "Front 29",
    positions: { bottom: 50, right: 90 },
  },
  {
    id: "Front 30",
    positions: { bottom: 0, left: 90 },
  },
  {
    id: "Front 31",
    positions: { bottom: 0, right: 90 },
  },
];

export const backInjuriesArrangements = [
  {
    id: "Back 1",
    positions: { top: 0, left: 106 },
  },
  {
    id: "Back 2",
    positions: { top: 35, left: 106 },
  },
  {
    id: "Back 3",
    positions: { top: 65, left: 65 },
  },
  {
    id: "Back 4",
    positions: { top: 65, left: 90 },
  },
  {
    id: "Back 5",
    positions: { top: 65, right: 80 },
  },
  {
    id: "Back 6",
    positions: { top: 65, right: 65 },
  },
  {
    id: "Back 7",
    positions: { top: 85, left: 85 },
  },
  {
    id: "Back 8",
    positions: { top: 85, left: 105 },
  },

  {
    id: "Back 9",
    positions: { top: 100, right: 85 },
  },
  {
    id: "Back 10",
    positions: { top: 100, left: 65 },
  },
  {
    id: "Back 11",

    positions: { top: 100, right: 55 },
  },
  {
    id: "Back 12",

    positions: { top: 120, left: 45 },
  },
  {
    id: "Back 13",

    positions: { top: 120, right: 40 },
  },
  {
    id: "Back 14",

    positions: { top: 140, left: 45 },
  },
  {
    id: "Back 15",

    positions: { top: 140, right: 40 },
  },
  {
    id: "Back 16",
    positions: { top: 130, left: 85 },
  },
  {
    id: "Back 17",

    positions: { top: 90, left: 100 },
  },
  {
    id: "Back 18",
    positions: { top: 130, right: 85 },
  },
  {
    id: "Back 19",

    positions: { top: 150, left: 105 },
  },
  {
    id: "Back 20",

    positions: { bottom: 180, left: 90 },
  },
  {
    id: "Back 21",
    positions: { bottom: 180, right: 85 },
  },
  {
    id: "Back 22",
    positions: { bottom: 170, left: 40 },
  },
  {
    id: "Back 23",
    positions: { bottom: 170, right: 40 },
  },
  {
    id: "Back 24",
    positions: { bottom: 130, left: 90 },
  },
  {
    id: "Back 25",
    positions: { bottom: 130, right: 80 },
  },
  {
    id: "Back 26",
    positions: { bottom: 90, left: 90 },
  },
  {
    id: "Back 27",
    positions: { bottom: 90, right: 90 },
  },

  {
    id: "Back 28",
    positions: { bottom: 50, left: 90 },
  },
  {
    id: "Back 29",

    positions: { bottom: 50, right: 90 },
  },
  {
    id: "Back 30",
    positions: { bottom: 0, left: 85 },
  },
  {
    id: "Back 31",

    positions: { bottom: 0, right: 85 },
  },
];

//reference for FRONT 19
// { id: "Front 19", show: false, position: "top-52 left-24 -mt-1 ml-2", positions: { left: 95, bottom: 200 }, },
